/* Space Grotesk Light */
@font-face {
  font-family: "Space Grotesk";
  src: url("../assets/fonts/SpaceGrotesk-Light.woff2") format("woff2");
  font-weight: 300; /* Light */
  font-style: normal;
}

/* Space Grotesk Regular */
@font-face {
  font-family: "Space Grotesk";
  src: url("../assets/fonts/SpaceGrotesk-Regular.woff2") format("woff2");
  font-weight: 400; /* Regular */
  font-style: normal;
}

/* Space Grotesk Medium */
@font-face {
  font-family: "Space Grotesk";
  src: url("../assets/fonts/SpaceGrotesk-Medium.woff2") format("woff2");
  font-weight: 500; /* Medium */
  font-style: normal;
}

/* Space Grotesk Bold */
@font-face {
  font-family: "Space Grotesk";
  src: url("../assets/fonts/SpaceGrotesk-Bold.woff2") format("woff2");
  font-weight: 700; /* Bold */
  font-style: normal;
}
