@import "common";

@mixin size-xs2 {
  height: 12px;
  width: 12px;
}

@mixin size-xs {
  height: 16px;
  width: 16px;
}

@mixin size-s {
  height: 20px;
  width: 20px;
}

@mixin size-m {
  height: 24px;
  width: 24px;
}

@mixin size-l {
  height: 32px;
  width: 32px;
}

@mixin size-xl {
  height: 36px;
  width: 36px;
}

@mixin size-xl2 {
  height: 48px;
  width: 48px;
}

@mixin size-xl3 {
  height: 54px;
  width: 54px;
}

@mixin size-xl4 {
  height: 72px;
  width: 72px;
}

@mixin size-xl5 {
  height: 90px;
  width: 90px;
}

@mixin size-full {
  height: 100%;
  width: 100%;
}

@mixin icon-size {
  &.xs2 {
    @include size-xs2;
  }

  &.xs {
    @include size-xs;
  }

  &.s {
    @include size-s;
  }

  &.m {
    @include size-m;
  }

  &.l {
    @include size-l;
  }

  &.xl {
    @include size-xl;
  }

  &.xl2 {
    @include size-xl2;
  }

  &.xl3 {
    @include size-xl3;
  }

  &.xl4 {
    @include size-xl4;
  }

  &.xl5 {
    @include size-xl5;
  }

  &.full {
    @include size-full;
  }
}

.ui-icon {
  @include flex-row-center;
  @include icon-size;

  &-svg {
    @include icon-size;
  }
}
