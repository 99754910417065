// -------------------------------------
// Custom variables
// -------------------------------------

// Header
$header-height: rem(95px);
$header-mobile-height: rem(74.5px);

// controls
$border-radius-control: rem(6px);
$icon-size-control: rem(24px);
$control-height: rem(30px);

// border radius
$border-radius-0: rem(2px);
$border-radius-1: rem(4px);
$border-radius-2: rem(8px);
$border-radius-3: rem(16px);

// scrollbar
$scrollbar-horizontal-height: 10px;
$scrollbar-vertical-width: 6px;

// button
$border-radius-button: rem(8px);

// select
$select-sm-height: rem(40px);
$select-mobile-sm-height: rem(40px);
$select-mobile-sm-width: rem(40px);
$select-height: rem(32px);
$select-arrow-size: rem(16px);

// logo
$logo-width: rem(240px);
$logo-mobile-width: rem(150px);
$logo-height: rem(55px);
$logo-mobile-height: rem(34.5px);

// pages
$pages-content-tablet-plus-width: rem(520px);
$pages-content-mobile-width: 100%;
$pages-content-mobile-margin: 200px;

// footer
$footer-height: rem(168px);
$footer-mobile-height: rem(258px);

// schemas
$schemas-page-title-width: rem(960px);
$schema-title-width: rem(280px);
$schema-title-tablet-width: rem(135px);
$schema-title-mobile-width: rem(108px);
$schema-height: rem(300px);
$schema-mobile-width: rem(180px);
$schema-smallest-mobile-width: rem(148px);
$schema-height: rem(270px);
$schema-tablet-height: rem(250px);
$schema-mobile-height: rem(180px);
$schema-first-tablet-height: rem(164px);
$schema-first-mobile-height: rem(90px);

// sign-in form
$form-width: rem(520px);
$toast-width: 332px;
$toast-mobile-width: 300px;

// banner
$banner-height: rem(77px);
$banner-mobile-height: rem(56px);

// Social Icons
$social-icons-container-height: rem(50px);
$social-icons-container-mobile-height: rem(100px);

// Top bar
$top-left-lighter-width: rem(415px);
$top-left-lighter-height: rem(249px);

// Main Section
$main-section-title-desktop-width-en: rem(420px);
$main-section-title-desktop-width-ru: rem(532px);
$main-section-sub-title-desktop-width: rem(486px);
