@import "global";
@import "@angular/cdk/overlay-prebuilt.css";

:root {
  --select-dropdown-max-height: 252px;

  /* Colors */
  --base--background: #090616;
  --neutral--400: #6a6a6b;
  --base--white: #f6f1ff;
  --white--72: #ffffffb8;
  --black--64: #000000a3;
  --white--64: #ffffffa3;
  --radius--40px: 40px;
  --white--12: #ffffff1f;
  --white--8: #ffffff14;
  --white--16: #ffffff29;
  --neutral--800: #1b1b1d;
  --white--6: #ffffff0f;
  --white--2: #ffffff05;
  --white--10: #ffffff1a;
  --base--accent-secondary: #b3a3ff;
  --base--accent-main: #6351b7;
  --base--light: #d8d3ed;
  --radius--24px: 24px;
  --white--4: #ffffff0a;
  --radius--8px: 8px;
  --white--1: #ffffff03;
  --radius--12px: 12px;
  --radius--20px: 20px;
  --black--48: #0000007a;
  --accent-seond: #f2759c;
  --purple--250: #b98dfc;
  --white--24: #ffffff3d;
  --radius--6px: 6px;
  --radius--16px: 16px;
  --neutral--300: #a6a6a6;
  --neutral--700: #252527;
  --base--black: #090616;
  --black--2: #00000005;
  --black--80: #000c;
  --black--72: #000000b8;
  --black--56: #0000008f;
  --black--40: #0006;
  --black--36: #0000005c;
  --black--32: #00000052;
  --black--20: #0003;
  --black--24: #0000003d;
  --black--16: #00000029;
  --black--12: #0000001f;
  --black--10: #0000001a;
  --black--8: #00000014;
  --black--6: #0000000f;
  --black--4: #0000000a;
  --black--1: #00000003;
  --blue--250: #18282a;
  --blue--500: #2e4d52;
  --blue--750: #5c9ba3;
  --blue--1000: #8deffc;
  --gray--100: #fafafa;
  --green--1000: #182a1b;
  --green--750: #2e5234;
  --green--500: #5ca367;
  --green--250: #8dfc9d;
  --neutral--900: #161618;
  --neutral--600: #303031;
  --neutral--500: #424243;
  --pink--1000: #2a1826;
  --pink--750: #522e4a;
  --pink--500: #a35c94;
  --pink--250: #fc8de4;
  --purple--1000: #1f182a;
  --purple--750: #3c2e52;
  --purple--500: #785ca3;
  --radius--32px: 32px;
  --radius--10px: 10px;
  --radius--4px: 4px;
  --white--80: #fffc;
  --white--56: #ffffff8f;
  --white--48: #ffffff7a;
  --white--40: #fff6;
  --white--36: #ffffff5c;
  --white--32: #ffffff52;
  --white--20: #fff3;
  --yellow--1000: #2a2618;
  --yellow--750: #51492f;
  --yellow--500: #a3945c;
  --yellow--250: #fce48d;
}

body {
  font-family: $base-font-family;
  font-size: $base-font-size;
  background: $color-bg-primary;
  display: flex;
  justify-content: center;
  overflow: hidden scroll;

  &.landing-page {
    &::before {
      content: "";
      position: absolute;
      width: $top-left-lighter-width;
      height: $top-left-lighter-height;
      opacity: 1;
      left: 0;
      top: 0;
      background-image: url("/assets/top-left-logo-lighter.png");
      mix-blend-mode: overlay;
      z-index: -1;
      max-width: 120vw;

      @include breakpoint(2xl) {
        left: 10%;
      }

      @include breakpoint-max(md) {
        left: -20%;
      }
    }
  }
}

.highlighted {
  @include text-gradient($color-bg-hilighted);

  &_primary {
    @include text-gradient($color-bg-hilighted-2);
  }
}

.page-title,
.page-sub-title {
  color: $color-text-primary;
  text-align: center;
}

.page-title {
  @include font-500-36;

  @include breakpoint-max(md) {
    @include font-500-27;
  }
}

.page-sub-title {
  color: $periwinkle;

  @include font-300-14;
}

.link {
  color: $color-link;

  @include link-underline;
  @include font-300-18;

  @include breakpoint-max(md) {
    @include font-400-16;
  }
}
