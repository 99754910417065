@mixin button-reset($width: true) {
  @include _reset;
  @include _font-smoothing;

  display: inline-block;
  background: none;
  appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  letter-spacing: 0;
  text-decoration: none;

  @if $width == true {
    width: 100%;
  }

  &::-moz-focus-inner {
    border: 0;
  }
}

@mixin _reset {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;

  & > *,
  & > *::before,
  & > *::after {
    box-sizing: inherit;
  }
}

// Only applied to bold weight text
@mixin _font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin background-gradient($gradient) {
  background: $gradient;
}

@mixin invalid-control {
  color: $color-state-error;
  caret-color: $color-state-error;
  border: $border-radius-0 solid $color-state-danger;

  + .control-icon {
    color: $color-state-danger;
  }

  &::placeholder {
    color: $color-state-error;
  }
}

@mixin link-underline {
  cursor: pointer;
  text-decoration: underline;
}

@mixin flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@mixin flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin text-gradient($backgroundColor) {
  background: $backgroundColor;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

@mixin border-gradient {
  border: 1px solid transparent;
  border-image-source: $border-gradient;
  border-image-slice: 1;
}

@mixin section-padding {
  width: 100%;
  padding: 0 $sp-12;

  @include breakpoint(xl) {
    padding: 0 $sp-120;
  }

  @include breakpoint-max(xl) {
    padding: 0 $sp-40;
  }

  @include breakpoint-max(md) {
    padding: 0 $sp-36;
  }
}

@mixin restricted-max-width($max-width: 1920px) {
  @include breakpoint(md) {
    max-width: $max-width;
    width: 100%;
  }
}
