// -------------------------------------
// Breakpoints
// -------------------------------------
$breakpoints: (
  xs: 320px,
  sm: 360px,
  md: 768px,
  xl: 1366px,
  2xl: 1920px,
);

// -------------------------------------
// Content Max Width
// -------------------------------------
$content-max-width: rem(1920px);

// -------------------------------------
// @mixin: breakpoint($size)
// -------------------------------------
// usage:
// @include breakpoint(md or 450px) {
// - styles here --
// }
// -------------------------------------
@mixin breakpoint($size) {
  @if map-has-key($breakpoints, $size) {
    @media (min-width: map-get($breakpoints, $size)) {
      @content;
    }
  } @else {
    @media (min-width: $size) {
      @content;
    }
  }
}

// -------------------------------------
// @mixin: breakpoint-max($size)
// -------------------------------------
// usage:
// @include breakpoint-max(md or 450px) {
// - styles here --
// }
// -------------------------------------
@mixin breakpoint-max($size) {
  @if map-has-key($breakpoints, $size) {
    // The maximum value is reduced by 0.02px to work around the limitations of
    // `min-` and `max-` prefixes and with fractional viewport sizes.
    // See: https://www.w3.org/TR/mediaqueries-4/#mq-min-max
    // Use 0.02px rather than 0.01px to work around a current rounding bug in Safari.
    // See https://bugs.webkit.org/show_bug.cgi?id=178261
    $breakpoint-max-range-precision: 0.02px;

    @media (max-width: map-get($breakpoints, $size) - $breakpoint-max-range-precision) {
      @content;
    }
  } @else {
    @media (max-width: $size) {
      @content;
    }
  }
}
